<i18n src="@/i18n/components/Pagination.json"></i18n>
<template>
	<div v-if="total" class="px-4 py-3 flex flex-wrap items-center justify-between" :class="{'border-t border-gray-200': !onlyNavigation}">
		<template v-if="!onlyNavigation">
			<div class="w-full sm:w-auto mt-2 sm:mt-0">
				<p class="text-sm leading-5 text-gray-700">
					{{ $t("pagination.showing") }}
					<span class="font-medium">{{ from }}</span>
					-
					<span class="font-medium">{{ to }}</span>
					{{ $t("pagination.showing_of") }}
					<span class="font-medium">{{ total }}</span>
					{{ $t("pagination.results") }}
				</p>
			</div>
		</template>
		<div class="w-full mt-4 sm:w-auto flex-1 flex justify-between sm:hidden">
			<button
				v-if="current_page != 1"
				class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-turquoise active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
				@click="handlePreview">
				{{ $t("pagination.preview") }}
			</button>
			<button
				v-if="current_page < last_page"
				class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-turquoise active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
				@click="handleNext">
				{{ $t("pagination.next") }}
			</button>
		</div>
		<div v-if="total > per_page" class="ml-auto hidden sm:flex sm:items-center sm:justify-between">
            <span class="relative z-0 inline-flex shadow-sm">
                <button type="button"
						class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-turquoise focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
						@click="handlePreview">
                  <i class="fa fa-caret-left"></i>
                </button>

                <template v-for="index in pages">
                    <button type="button"
							:class="$tw('-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-turquoise focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150', buttonClass(index))"
							@click="handleSelectPage(index)">
                      {{ index }}
                    </button>
                </template>

                <button type="button"
						class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-turquoise focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
						@click="handleNext">
                  <i class="fa fa-caret-right"></i>
                </button>
              </span>
		</div>
	</div>
</template>

<script>
export default {
	name: "Pagination",
	props: {
		total: Number,
		per_page: Number,
		current_page: Number,
		last_page: Number,
		from: Number,
		to: Number,
		onlyNavigation: Boolean,
		max_page: {
			type: Number,
			default: 8
		}
	},
	computed: {
		pages() {
			// TODO: Refactoring

			let firstPage = 1
			let lastPage = this.last_page + 1
			let currentPage = this.current_page
			let maxPage = this.max_page
			let maxViewPage = maxPage

			let diff = currentPage - maxPage

			let addFirstPage = false
			let addLastPage = false

			if (diff >= 0) {
				firstPage += diff + 1
				maxViewPage += diff + 1

				if ((currentPage + maxPage) >= lastPage) {
					firstPage = lastPage - maxPage
					maxViewPage = maxPage

					addFirstPage = firstPage > 1
				} else {
					addLastPage = true
				}
			} else if (lastPage > maxViewPage) {
				addLastPage = true
			}

			let list = []
			for (let i = firstPage; i < lastPage; i++) {

				if (i == firstPage && addFirstPage) {
					list.push(1)
					list.push("...")
				}

				if (i > maxViewPage && addLastPage) {

					list.push("...")
					list.push(this.last_page)

					break
				}

				list.push(i)
			}

			return list

		},
	},
	methods: {
		/**
		 * Handle select page
		 * @param index
		 * @returns void
		 */
		handleSelectPage(index) {
			if (this.current_page === index) return false

			this.$emit("select-page", index)
		},
		/**
		 * Handle next page
		 * @returns void
		 */
		handleNext() {
			if (this.current_page === this.last_page) return false

			this.$emit("select-page", this.current_page + 1)
		},
		/**
		 * Handle preview page
		 * @returns void
		 */
		handlePreview() {
			if (!this.current_page) return false

			this.$emit("select-page", this.current_page - 1)
		},
		buttonClass(index){
			if(index === this.current_page) return 'border-turquoise z-10'
		}
	}
}
</script>
