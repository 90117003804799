<i18n src="@/i18n/backoffice/dashboard/page.json"></i18n>
<template>
	<Card :title="$t('dashboard.last_12_months')">
		<div class="p-4">
			<canvas ref="chart"></canvas>
		</div>
	</Card>
</template>
<script>

import Card from "@/components/Card.vue"
import Chart from 'chart.js/auto'
import Dashboard from "@/API/Dashboard.js";

export default {
	name: "LastTwelveMonthsChart",
	components: {
		Card
	},
	data() {
		return {
			chartData: null
		}
	},
	methods: {
		async renderChart() {
			const [err, res] = await Dashboard.loadChart('shipments:last-twelve-months')

			this.chart = new Chart(this.$refs.chart, {
				type: 'line',
				data: res.data.data,
				tension: 0.4,
				options: {
					scales: {
						visible: false,
						y: {
							visible: false,
							beginAtZero: true
						}
					}
				}
			});
		}
	},
	mounted() {
		this.renderChart()
	}
}

</script>
